module services {
    export module applicationcore {
        export class dashboardService implements interfaces.applicationcore.IDashboardService {
            static $inject = ["$resource", "ENV", "$q", "$timeout","$http","generalService"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig,
                private $q: ng.IQService, private $timeout: ng.ITimeoutService,private $http: ng.IHttpService,public generalService: interfaces.applicationcore.IGeneralService) {
            }

            nvd3Dashboard(): ng.resource.IResourceClass<interfaces.applicationcore.INVD3Dashboard> {
                return this.$resource<interfaces.applicationcore.INVD3Dashboard>(this.ENV.DSP_URL + "NVD3Dashboards", {
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: {
                                response: config.appResourceNVD3Interceptor.parseResponseOptions
                            }
                        }
                    });
            }


            getData(dashboardId: number, connectSearch: interfaces.applicationcore.IFilterSearchObject): ng.resource.IResourceClass<interfaces.applicationcore.INVD3Dashboard> {
                return this.$resource<interfaces.applicationcore.INVD3Dashboard>(this.ENV.DSP_URL + "NVD3Dashboards/GetData", {
                    dashboardId: dashboardId,
                    connectSearch: connectSearch
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: {
                                response: config.appResourceNVD3Interceptor.parseResponseOptions
                            }
                        }
                    });
            }

            getDashboardReport(reportCode: string, connectSearch: interfaces.applicationcore.IFilterSearchObject): ng.IPromise<void> {
               /* return this.$resource<interfaces.applicationcore.INVD3Dashboard>(this.ENV.DSP_URL + "NVD3Dashboards/GetData", {
                    reportCode: reportCode,
                    connectSearch: connectSearch
                }, {
                        query: {
                            method: 'GET',
                            isArray: false,
                            interceptor: {
                                response: config.appResourceNVD3Interceptor.parseResponseOptions
                            }
                        }
                    });*/
                    var pdfDownload = document.createElement("a");
                    document.body.appendChild(pdfDownload);
    
                    var url = this.ENV.DSP_URL + 'NVD3Dashboards/GetDashboardReport?';
    
                    var searchObject: interfaces.applicationcore.IFilterSearchObject = {
                        filters: []
                    }
                    searchObject = connectSearch;
    
                    url += 'connectSearch=' + btoa(JSON.stringify(searchObject));
    
                    url += "&reportCode=" + reportCode;
    
                    return this.$http({
                        method: 'GET',
                        responseType: 'arraybuffer',
                        url: url,
                    }).then((response) => {
                        var filename = response.headers()['x-filename'] ||  reportCode + '.xls';
                        var fileBlob = new Blob([response.data as BlobPart], { type: 'application/octet-stream' });
    
                        if (navigator.appVersion.toString().indexOf('.NET') > 0) { // for IE browser
                            window.navigator.msSaveBlob(fileBlob, filename);
                        } else { // for other browsers
                            var fileURL = window.URL.createObjectURL(fileBlob);
                            pdfDownload.href = fileURL;
                            pdfDownload.download = filename;
                            pdfDownload.click();
                            pdfDownload.remove();
                        }
    
                        this.$timeout(() => {
                            this.generalService.displayMessage("Your download has started.", Enum.EnumMessageType.Success);
                        });
                    }).catch((errorResponse) => {
                        var fileBlob = new Blob([errorResponse.data], { type: 'application/json' });
                        var fileURL = window.URL.createObjectURL(fileBlob);
                        const reader = new FileReader();
    
                        // This fires after the blob has been read/loaded.
                        reader.addEventListener('loadend', (e: ProgressEvent) => {
                            const text = e.target['result'];
    
                            this.$timeout(() => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>JSON.parse(text));
                            });
                        });
    
                        // Start reading the blob as text.
                        reader.readAsText(fileBlob);
                    });

            }



            dashboard(): ng.resource.IResourceClass<interfaces.applicationcore.IDashboard> {
                return this.$resource<interfaces.applicationcore.IDashboard>(this.ENV.DSP_URL + "Dashboards", {
                });
            }

            dashboardDropdown(): ng.resource.IResourceClass<interfaces.applicationcore.IDropdownModel> {
                return this.$resource<interfaces.applicationcore.IDropdownModel>(this.ENV.DSP_URL + "Dashboards/GetDashboardDropdown", {
                });
            }
        }
    }
    angular.module("app").service("dashboardService", services.applicationcore.dashboardService);
}